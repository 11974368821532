import type { MenuItem } from "~/models/MenuItem"

export const profile_menu: MenuItem[] = [
    // {
    //     title: 'shoppingCard.my',
    //     icon: 'credit_card',
    //     route: 'shoppingCard.my',
    //     color: 'grey-9',
    //     can: props => props.auth.user != null
    // },
    {
        title: "orders.my",
        route: "/profile/orders",
        icon: "request_quote",
        color: "grey-9",
        can: (props) => props.auth.user != null,
    },
    {
        title: "fields.beneficiary",
        route: "/profile/beneficiaries",
        icon: "users",
        color: "grey-9",
        can: (props) => props.auth.user != null,
    },
    // {
    //     title: 'reservations.my',
    //     route: 'reservations.my',
    //     icon: 'book_online',
    //     color: 'grey-9',
    //     can: props => props.auth.user != null
    // },
]

export const navbar_menu: {
    route_name: string
    route_current: string
    show_condition: Function | null
    name: string
}[] = [
    {
        route_name: "store",
        route_current: "store",
        show_condition: null,
        name: "home.store",
    },
    {
        route_name: "shopping-card-info",
        route_current: "shopping-card-info",
        show_condition: (page: { props: { enable_shopping_card_info: any } }) =>
            page.props.enable_shopping_card_info,
        name: "home.shopping_card_info",
    },
    {
        route_name: "store.services",
        route_current: "store.services",
        show_condition: () => false,
        name: "home.services",
    },
    {
        route_name: "blogs.index",
        route_current: "blogs.index",
        show_condition: null,
        name: "blog.title",
    },
    {
        route_name: "contact",
        route_current: "contact",
        show_condition: null,
        name: "home.contact_us",
    },
    {
        route_name: "about",
        route_current: "about",
        show_condition: (page: {
            props: { information: { enable_about_us: any } }
        }) => page.props.information.enable_about_us,
        name: "home.about_us",
    },
    {
        route_name: "frequent-asks",
        route_current: "frequent-asks",
        show_condition: (page: {
            props: { information: { enable_frequent_asks: any } }
        }) => page.props.information.enable_frequent_asks,
        name: "fields.faq",
    },
]

/**
 * Main menu
 *
 * @author Abel David.
 */
export const main_menu: MenuItem[] = [
    {
        title: "nav.manage",
        icon: "work",
        color: "grey-9",
        children: [
            // {
            //     title: 'nav.dashboard',
            //     icon: 'dashboard',
            //     color: 'grey-9',
            //     children: [
            //         {
            //             title: 'analytics.title',
            //             route: 'analytics',
            //             icon: 'area_chart',
            //             color: 'grey-9',
            //             can: 'read_analytics'
            //         }
            //     ]
            // },
            {
                title: "stores.title",
                route: "/admin/stores",
                icon: "store",
                color: "grey-9",
            },
            {
                title: "users.title",
                route: "/admin/users",
                icon: "group",
                color: "grey-9",
                //can: 'read_users'
            },
            {
                title: "roles.title",
                route: "/admin/roles",
                icon: "verified_user",
                color: "grey-9",
                can: "read_roles",
            },
            {
                title: "point_of_sales.title",
                route: "/point_of_sales",
                icon: "house",
                color: "grey-9",
                can: "read_pos",
            },
            {
                title: "products.title",
                icon: "inventory_2",
                color: "grey-9",
                children: [
                    {
                        title: "products.list",
                        route: "/products",
                        icon: "inventory_2",
                        color: "grey-9",
                        can: "read_products",
                    },
                    {
                        title: "categories.title",
                        route: "/categories",
                        icon: "category",
                        color: "grey-9",
                        can: "read_categories",
                    },
                    {
                        title: "attributes.title",
                        route: "/attributes",
                        icon: "extension",
                        color: "grey-9",
                        can: "read_attributes",
                    },
                ],
            },
            {
                title: "sale.title",
                icon: "attach_money",
                color: "grey-9",
                children: [
                    {
                        title: "invoices.title",
                        route: "/invoices",
                        icon: "receipt",
                        color: "grey-9",
                        can: "read_invoices",
                    },
                    {
                        title: "orders.title",
                        route: "/admin/orders",
                        icon: "request_quote",
                        color: "grey-9",
                        can: "read_orders",
                    },
                    {
                        title: "dispatchOrders.title",
                        route: "/admin/dispatch-orders",
                        icon: "request_quote",
                        color: "grey-9",
                        can: "read_dispatch_orders",
                    },
                    // {
                    //     title: 'reservations.title',
                    //     route: 'reservations.index',
                    //     icon: 'book_online',
                    //     color: 'grey-9',
                    //     can: 'read_reservation'
                    // },
                ],
            },
            // {
            //     title: 'services.title',
            //     route: 'services.index',
            //     icon: 'handyman',
            //     color: 'grey-9',
            //     can: 'read_services'
            // },
            {
                title: "offers.title",
                route: "/admin/offers",
                icon: "shop",
                color: "grey-9",
                can: "read_offers",
            },
            {
                title: "banners.title",
                route: "banners.index",
                icon: "image",
                color: "grey-9",
                can: "read_banners",
            },

            // {
            //     title: 'shoppingCard.sale_in_store',
            //     icon: 'storefront',
            //     route: 'sales_buy_card',
            //     color: 'grey-9',
            //     can: 'sale_buy_car_invoices'
            // },

            {
                title: "orderStatuses.title",
                route: "orderStatuses.index",
                icon: "update",
                color: "grey-9",
                can: "read_orderStatuses",
            },
            {
                title: "report.title",
                icon: "summarize",
                color: "grey-9",
                can: "read_reports",
                children: [
                    {
                        title: "report.for_sale",
                        route: "reports.sales",
                        icon: "point_of_sale",
                        can: "read_for_sale_reports",
                        color: "grey-9",
                    },
                    {
                        title: "report.for_inventary",
                        route: "reports.inventory",
                        icon: "inventory",
                        color: "grey-9",
                        can: "read_for_inventary_reports",
                    },
                    {
                        title: "report.orders",
                        color: "grey-9",
                        icon: "list_alt",
                        route: "reports.orders",
                        can: "read_for_order_reports",
                    },
                    {
                        title: "report.movements",
                        color: "grey-9",
                        icon: "add_business",
                        route: "reports.movements",
                        can: "read_for_movement_reports",
                    },
                    // {
                    //     title: 'shoppingCard.title',
                    //     color: 'grey-9',
                    //     icon: 'credit_card',
                    //     route: 'reports.shoppingCard',
                    //     can: 'report_shopping_cards'
                    // },
                    {
                        title: "report.salesaccounting",
                        color: "grey-9",
                        icon: "credit_card",
                        route: "reports.salesaccounting",
                        can: "read_for_sales_accounting_reports",
                    },
                    {
                        title: "report.sold_product",
                        color: "grey-9",
                        icon: "data_thresholding",
                        route: "reports.soldProduct",
                        can: "read_for_sold_products",
                    },
                ],
            },
            {
                title: "blog.title",
                color: "gray-9",
                icon: "rss_feed",
                children: [
                    {
                        route: "posts.index",
                        title: "post.title",
                        icon: "list",
                        color: "grey-9",
                        can: "read_post",
                    },
                ],
            },
            {
                title: "valoration.title",
                color: "gray-9",
                icon: "rss_feed",
                route: "/admin/criterias",
                can: "criteria_and_answer",
            },
            {
                title: "products.validation_review",
                color: "gray-9",
                icon: "rss_feed",
                route: "/admin/reviews",
                can: "rating_products",
            },
        ],
    },
    {
        title: "settings.title",
        icon: "settings",
        color: "grey-9",
        children: [
            {
                title: "settings.generals",
                route: "/admin/settings/generals",
                icon: "settings_suggest",
                color: "grey-9",
                can: ["read_settings", "update_settings"],
            },
            {
                title: "settings.customization",
                route: "/admin/settings/customization",
                icon: "palette",
                color: "grey-9",
                can: ["read_settings", "update_settings"],
            },
            {
                title: "Monedas",
                route: "coins.index",
                icon: "monetization_on",
                color: "grey-9",
                can: "read_coins",
            },
            {
                title: "Tasa de cambio",
                route: "exchange_rates.get-per-day",
                icon: "account_balance",
                color: "grey-9",
                can: "read_exchange_rate",
            },
        ],
    },
    {
        title: "nav.analytics",
        icon: "area_chart",
        color: "grey-9",
        children: [
            {
                title: "analytics.visits",
                route: "analytics.visits",
                icon: "group",
                color: "grey-9",
                can: "read_analytics",
            },
            {
                title: "analytics.sales",
                route: "analytics.sales",
                icon: "area_chart",
                color: "grey-9",
                can: "read_analytics",
            },
            {
                title: "analytics.products",
                route: "analytics.mostsold",
                icon: "bar_chart",
                color: "grey-9",
                can: "read_analytics",
            },
        ],
    },
]
