<script setup lang="ts">
import { computed, ref } from "vue"
import { useSearchStore } from "~/stores/searchStore"
import Select from "./Select.vue"
import { capitalizeFirstLetter } from "~/common/helpers"
/**
 *
 *  Props and emits section
 *
 *
 */

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const router = useRouter()
const route = useRoute()
const queryStore = useSearchStore()
const { query, category } = storeToRefs(queryStore)
const search = ref(query.value)
const categorySelected = ref(category.value)
const categoriesStore = useCategoriesStore()
const { categories } = storeToRefs(categoriesStore)
const shopListStore = useShopListStore()

const { $bus } = useNuxtApp()

/**
 *
 *  Life cicle events
 *
 *
 */

onMounted(() => {
    if (route.query.query) {
        queryStore.setQuery(route.query.query as string)
        search.value = query.value
    }
    if (route.query.category) {
        queryStore.setCategory(route.query.category as string)
        categorySelected.value = findCategory(category.value) ?? undefined
    }
})

/**
 *
 *  Functions section
 *
 *
 */

const findCategory = (id: number | undefined) =>
    categories.value.find((item: any) => item.id === id)

const searchInStore = () => {
    queryStore.setQuery(search.value)
    queryStore.setCategory(categorySelected.value)

    const params = {
        ...route.query,
        query: search.value,
        category: categorySelected.value?.id,
    }

    navigateTo({ path: "/store", query: params })

    $bus.$emit("update:product-list", { params })
}

const cleanSearchInStore = () => {
    search.value = ""
    categorySelected.value = undefined
    queryStore.setQuery(search.value)
    queryStore.setCategory(categorySelected.value)
    const queries = route.query
    delete queries.query
    if (route.path !== "/store") {
        router.push({
            path: shopListStore.currentShop
                ? `/store/${shopListStore.currentShop.slug}`
                : "/store",
            query: { ...queries },
        })
    }
    router.push({ path: route.path, query: { ...queries } })
}
</script>
<template>
    <div
        class="tw-border tw-border-customGray-200 tw-rounded-full tw-overflow-hidden !tw-bg-transparent tw-mx-auto tw-w-full md:tw-w-[520px] md:tw-max-w-[520px] tw-h-10 tw-flex tw-gap-4 tw-pl-4 tw-pr-1"
    >
        <div class="tw-flex tw-flex-1 tw-w-full tw-gap-2 tw-items-center">
            <Select
                :label="$t('home.category_search')"
                borderless
                class="!tw-w-full category-search"
                v-model:modelValue="categorySelected"
                :options="
                    categories.map((c) => ({
                        label: capitalizeFirstLetter(c.label),
                        value: c.id,
                    }))
                "
            />
            <q-separator class="tw-h-1/2 tw-w-[2px] tw-bg-customGray-200" />
        </div>
        <div class="tw-flex tw-justify-between">
            <q-input
                rounded
                :outlined="false"
                borderless
                :label="null"
                dense
                v-model:model-value="search"
                class="tw-my-auto tw-text-sm tw-w-max md:tw-w-full tw-font-extralight tw-text-white lg:tw-text-customGray-400 tw-caret-primary !tw-bg-transparent"
                @keyup.enter="searchInStore"
                @focus="() => (focus = true)"
                @blur="blur"
            >
                <template v-slot:append>
                    <q-icon
                        dense
                        name="close"
                        size="16px"
                        round
                        flat
                        @click="cleanSearchInStore"
                        class="tw-cursor-pointer tw-text-secondary hover:tw-text-primary tw-transition-all tw-duration-500 tw-ease-in-out"
                        v-if="!!search"
                    ></q-icon>
                </template>

                <template v-slot:label>
                    <div>
                        <span class="tw-text-customGray-400">{{
                            $t("home.search")
                        }}</span>
                    </div>
                </template>
            </q-input>
            <q-icon
                dense
                name="search"
                size="16px"
                round
                flat
                @click="searchInStore"
                class="tw-text-md tw-cursor-pointer tw-bg-primary hover:tw-bg-primary-800 tw-transition-all tw-duration-500 tw-ease-in-out tw-text-white tw-my-auto tw-px-4 tw-py-2 tw-rounded-full"
            ></q-icon>
        </div>
    </div>
</template>
<style>
.category-search .q-field__inner {
    @apply !tw-h-10 !tw-w-max md:!tw-w-[180px];
}
</style>
