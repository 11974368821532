<script setup lang="ts">
import {
    SUPPORTED_PAGES_ENUM,
    type SUPPORTED_PAGES,
} from "~/common/breadCrumbs"
import { useCurrentPageStore } from "~/stores/currentPageStore"
import { ref } from "vue"
import { useShopListStore } from "~/stores/shopListStore"
import type { ShopSelectItem } from "~/models/Shop"
/**
 *
 *  Props and emits section
 *
 *
 */

defineProps<{
    menuItems: {
        route: string
        name: string
        page: SUPPORTED_PAGES
    }[]
}>()

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const router = useRouter()
const { currentPage } = useCurrentPageStore()
const openDropdown = ref(false)
const shopListStore = useShopListStore()
const loadingShop = computed(() => {
    return shopListStore.loading
})
const route = useRoute()
const spinnerColor = computed(() => {
    return route.path === "/" ? "blue-grey" : "white"
})

/**
 *
 *  Life cicle events
 *
 *
 */

/**
 *
 *  Functions section
 *
 *
 */

const toggleOpenDropdown = () => {
    if (loadingShop.value) return
    openDropdown.value = !openDropdown.value
}

const goToStore = (shop?: ShopSelectItem) => {
    if (shop) {
        shopListStore.fetchCurrentShop(shop)
    } else {
        shopListStore.clearCurrentShop()
    }
    return shop
        ? router.push({ path: `/store/${shop.slug}` })
        : router.push({ path: "/store" })
}
</script>

<template>
    <div
        class="tw-flex tw-flex-1 tw-w-full tw-items-center tw-gap-5 xl:tw-gap-10"
    >
        <template v-for="(menuItem, i) in menuItems" :key="i">
            <template v-if="menuItem.page === SUPPORTED_PAGES_ENUM.Store">
                <q-btn-dropdown
                    class="tw-capitalize tw-font-titilium tw-rounded-full tw-h-10 tw-min-w-[140px] tw-text-base tw-font-bold !tw-border-0 !tw-w-max tw-bg-secondary hover:tw-bg-secondary-800 !tw-px-4 !tw-py-2 tw-text-white"
                    flat
                    dropdown-icon="none"
                >
                    <template #label>
                        <div
                            class="tw-flex tw-items-center tw-w-full tw-justify-between tw-text-inherit"
                            @click="toggleOpenDropdown"
                        >
                            <template v-if="shopListStore.currentShop">
                                <q-item-label
                                    class="tw-px-auto tw-font-titilium"
                                    >{{
                                        shopListStore.currentShop.name
                                    }}</q-item-label
                                >
                            </template>
                            <template v-else>
                                <q-item-label
                                    class="tw-px-auto tw-font-titilium"
                                    >{{ menuItem.name }}</q-item-label
                                >
                            </template>
                            <div class="tw-flex tw-items-center tw-gap-1">
                                <div
                                    v-if="loadingShop"
                                    class="tw-cursor-pointer tw-relative all-pointer-events"
                                >
                                    <q-spinner-ios
                                        size="20px"
                                        :color="spinnerColor"
                                    />
                                    <q-tooltip
                                        class="text-white bg-primary shadow-4"
                                        anchor="top middle"
                                        self="bottom middle"
                                        :offset="[10, 10]"
                                        >Cargando
                                        {{ $t("fields.stores") }}</q-tooltip
                                    >
                                </div>
                                <template v-else>
                                    <q-icon
                                        v-if="!openDropdown"
                                        name="keyboard_arrow_down"
                                        size="20px"
                                    />
                                    <q-icon
                                        v-else
                                        name="keyboard_arrow_up"
                                        size="20px"
                                    />
                                </template>
                            </div>
                        </div>
                    </template>
                    <q-list>
                        <q-item
                            class="!tw-shadow-none !tw-bg-transparent"
                            clickable
                            v-close-popup
                            @click="goToStore()"
                        >
                            <q-item-section
                                class="tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-2"
                            >
                                <q-item-label
                                    class="!tw-text-sm lg:!tw-text-base"
                                    >Todas</q-item-label
                                >
                            </q-item-section>
                        </q-item>
                        <q-item
                            class="!tw-shadow-none !tw-bg-transparent"
                            clickable
                            v-close-popup
                            v-for="(shop, i) in shopListStore.shopList"
                            :key="i"
                            @click="goToStore(shop)"
                        >
                            <q-item-section
                                class="tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-2"
                            >
                                <q-item-label
                                    class="!tw-text-sm lg:!tw-text-base"
                                    >{{ shop.name }}</q-item-label
                                >
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-btn-dropdown>
            </template>
            <NuxtLink
                v-else
                :to="menuItem.route"
                class="tw-hidden md:tw-flex tw-items-center tw-text-sm lg:tw-text-base tw-w-max !tw-p-0 tw-text-customGray-500 hover:tw-text-primary tw-font-bold"
                >{{ menuItem.name }}</NuxtLink
            >
        </template>
    </div>
</template>
