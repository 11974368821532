<script setup lang="ts">
import CustomTreeItem from "./CustomTreeItem.vue"
/**
 *
 *  Props and emits section
 *
 *
 */

export type TreeProps = any & {
    id: number
    label: string
    selected: boolean
    value: string
    children: TreeProps[]
}

const props = defineProps<{
    options: TreeProps[]
}>()

const emits = defineEmits<{
    (e: "onSelect", optionId: number): void
}>()

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const computedOptions = computed(() => {
    return props.options.map((option) => {
        return {
            ...option,
            id: option.id,
            value: option.value,
            label: option.label,
            children: option.children,
        }
    })
})

/**
 *
 *  Life cicle events
 *
 *
 */

/**
 *
 *  Functions section
 *
 *
 */
const onSelect = (optionId: number) => {
    emits("onSelect", optionId)
}
</script>

<template>
    <div>
        <div v-if="computedOptions.length === 0">
            <h6 class="tw-text-customGray-400 tw-font-titilium">
                No hay categorias para mostrar
            </h6>
        </div>
        <template v-for="option in computedOptions">
            <CustomTreeItem :option="option" @onClick="onSelect" />
        </template>
    </div>
</template>

<style scoped></style>
