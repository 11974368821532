import {
    SUPPORTED_PAGES_ENUM,
    type SUPPORTED_PAGES,
} from "~/common/breadCrumbs"

export const useCurrentPageStore = defineStore("currentPage", {
    state: (): { currentPage: SUPPORTED_PAGES } => ({
        currentPage: SUPPORTED_PAGES_ENUM.Home,
    }),
    actions: {
        setCurrentPage(currentPage: SUPPORTED_PAGES) {
            this.currentPage = currentPage
        },
    },
})
