import type { ShopSelectItem, Shop } from "~/models/Shop"
import { useOFetchCustom } from "~/composables/useOFetchCustom"
import type { DefaultSettings } from "~/models/DefaultSettings"

export const useShopListStore = defineStore("shopList", {
    state: (): {
        shopList: ShopSelectItem[]
        currentShop?: Shop
        currentShopSettings?: DefaultSettings
        loading: boolean
    } => ({
        shopList: [],
        currentShop: undefined,
        currentShopSettings: undefined,
        loading: false
    }),
    actions: {
        fetchShopList() {
            this.loading = true
            const { get: getShopList } = useOFetchCustom("public/stores")

            getShopList(undefined, {
                onSuccess: ({ data }) => {
                    this.shopList = data
                    this.fetchCurrentShop()
                    this.loading = false
                },
                onError: (error: any) => {
                    console.log(error)
                    this.shopList = []
                    this.loading = false
                },
            })
        },
        fetchCurrentShop(shop?: ShopSelectItem) {
            const params = useRouter().currentRoute.value.params?.store

            if (shop) {
                this.currentShop = this.getCurrentShop(shop.slug)
            } else if (params) {
                this.currentShop = this.getCurrentShop(params as string)
            }
        },
        clearCurrentShop() {
            const storageShops: Shop[] = localStorage.getItem("shops")
                ? JSON.parse(localStorage.getItem("shops") as string)
                : null
            if (storageShops) {
                localStorage.removeItem("shops")
                this.currentShop = undefined
                this.currentShopSettings = undefined
            }
        },
        setSettingsStore(settings: DefaultSettings) {
            this.currentShopSettings = settings
        },
        async inferSettings() {
            try {
                const resource = `init-settings/store/${this.currentShop.id}`
                const { data } = await useOFetch("GET", resource)
                this.setSettingsStore(data)
            } catch (e) {
                console.log(e)
                this.setSettingsStore(undefined)
            }
        },
        getCurrentShop(shopParams: string) {
            const storageShops: Shop[] = localStorage.getItem("shops")
                ? JSON.parse(localStorage.getItem("shops") as string)
                : null
            let currentShop: Shop | null = null
            // verificar si el shop es una tienda del local storage
            if (storageShops?.some((item) => item.slug === shopParams)) {
                return storageShops.find((item) => item.slug === shopParams)
            }

            // fetch para obtener la tienda
            this.loading = true
            const { get: getShop } = useOFetchCustom(
                `public/stores/${shopParams}/info`
            )
            getShop(undefined, {
                onSuccess: ({ data }) => {

                    this.currentShop = data
                    // guardar la tienda en localStorage, si existen 3 tiendas en local storage eliminar la primera
                    let newStorageShops = []
                    if (!storageShops) {
                        newStorageShops = [this.currentShop]
                    } else if (storageShops.length === 3) {
                        newStorageShops = [
                            storageShops.slice(1, 2),
                            this.currentShop,
                        ]
                    } else {
                        newStorageShops = [...storageShops, this.currentShop]
                    }

                    localStorage.setItem(
                        "shops",
                        JSON.stringify(newStorageShops)
                    )
                    this.loading = false
                },
                onError: (error: any) => {
                    console.log(error)
                    this.loading = false
                },
            })

            return currentShop
        },
    },
})
