<template>
    <div
        class="tw-w-full tw-hidden lg:tw-flex tw-items-center tw-gap-8 xl:tw-text-xl tw-leading-none tw-tracking-tight"
    >
        <div class="tw-flex tw-justify-between tw-w-full tw-items-center">
            <a class="tw-hidden lg:tw-inline" href="/">
                <ApplicationLogo class="tw-w-[204px] tw-h-[80px]" />
            </a>
            <HomeSearch />
        </div>
        <div
            class="tw-flex tw-gap-4 tw-min-w-max tw-w-1/5 tw-flex-1 tw-justify-evenly tw-items-center"
        >
            <ProfileMenu></ProfileMenu>
            <ShopCartButton></ShopCartButton>
        </div>
    </div>
</template>
<style scoped>
.header-shaw {
    box-shadow: 0px 0px 4.7px 4px rgba(48, 151, 153, 0.2);
}

.carrito-container {
    position: relative;
    display: inline-block;
}

.carrito-cantidad {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #0674bf;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 0px 4px;
    text-align: center;
    font-size: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
