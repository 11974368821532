import { useOFetchCustom } from "~/composables/useOFetchCustom"
import type { Category } from "~/models/Category"

export const useCategoriesStore = defineStore("categories", {
    state: (): {
        categories: Category[]
        categoriesByCurrentShop: Category[]
        loading: boolean
    } => ({
        categories: [],
        categoriesByCurrentShop: [],
        loading: false
    }),
    actions: {
        fetchCategories() {
            this.loading = true
            const { get: getCategories } = useOFetchCustom("public/categories")
            let tempCategories = [...this.categories]
            this.categories = []
            getCategories(undefined, {
                onSuccess: ({ data }) => {
                    this.categories = data
                    this.loading = false
                },
                onError: (error: any) => {
                    console.log(error)
                    this.categories = tempCategories.length === 0 ? [] : tempCategories
                    this.loading = false
                },
            })
        },
        fetchCategoriesByStore(store: string) {
            this.loading = true
            const { get: getCategoriesByStore } = useOFetchCustom(
                `public/${store}/categories`
            )

            let tempCategories = [...this.categoriesByCurrentShop]
            this.categoriesByCurrentShop = []

            getCategoriesByStore(undefined, {
                onSuccess: ({ data }) => {
                    this.categoriesByCurrentShop = data
                    this.loading = false
                },
                onError: (error: any) => {
                    console.log(error)
                    this.categoriesByCurrentShop = tempCategories.length === 0 ? [] : tempCategories
                    this.loading = false
                },
            })
        },
    },
})
