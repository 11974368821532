<script setup lang="ts">
import { ref } from "vue"

/**
 *
 *  Props and emits section
 *
 *
 */

const props = defineProps<{
    title: string
    opened: boolean
    alwaysOpen: boolean
}>()

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const showDetails = ref(props.opened || props.alwaysOpen)

/**
 *
 *  Life cicle events
 *
 *
 */

/**
 *
 *  Functions section
 *
 *
 */

const toggleDetails = () => {
    if (props.alwaysOpen) return (showDetails.value = true)
    showDetails.value = !showDetails.value
    return showDetails.value
}
</script>

<template>
    <div
        class="tw-w-full tw-border tw-rounded-[3px] tw-border-customGray-100 tw-p-8"
    >
        <div class="tw-w-full tw-flex tw-justify-between tw-items-center">
            <h2 class="tw-text-primary tw-font-semibold tw-text-2xl">
                {{ props.title }}
            </h2>
            <template v-if="!alwaysOpen">
                <q-icon
                    class="tw-cursor-pointer"
                    name="fa-solid fa-angle-up"
                    size="16px"
                    v-if="showDetails"
                    @click="toggleDetails"
                />
                <q-icon
                    class="tw-cursor-pointer"
                    name="fa-solid fa-angle-down"
                    size="16px"
                    v-else
                    @click="toggleDetails"
                />
            </template>
        </div>
        <div
            class="tw-flex tw-flex-col tw-gap-7 tw-mt-7 tw-mr-10 tw-overflow-hidden"
            v-if="alwaysOpen || showDetails"
        >
            <slot />
        </div>
    </div>
</template>
