<script lang="ts" setup>
import { useDefaultSettingsStore } from "~/stores/defaultSettingsStore"

/**
 *
 *  Props and emits section
 *
 *
 */

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const defaultSettingsStore = useDefaultSettingsStore()
const { settings } = storeToRefs(defaultSettingsStore)

const information = computed(() => {
    return settings.value?.information
})
const contacts = computed(() => {
    console.log(defaultSettingsStore)
    return settings.value?.contacts
})
const label_schedule_footer = computed(() => {
    return settings.value?.contacts?.schedule
})

const scheduleLabels = computed(() => {
    const dayWeek = [
        "Lunes",
        "Martes",
        "Miercoles",
        "Jueves",
        "Viernes",
        "Sábado",
        "Domingo",
        "Lunes",
    ]
    const labels = ref<string[]>([])
    if (
        label_schedule_footer &&
        label_schedule_footer.value &&
        label_schedule_footer.value.length > 0
    ) {
        label_schedule_footer.value.forEach(
            (schedule: { day_week: any[]; hours: string | any[] }) => {
                let label = ""

                schedule.day_week.forEach((week) => {
                    if (Array.isArray(week) && week.length === 2) {
                        label +=
                            (label ? ", " : "") +
                            dayWeek[week[0]] +
                            " a " +
                            dayWeek[week[1]]
                    } else {
                        label += (label ? ", " : "") + dayWeek[week]
                    }
                })

                if (schedule.hours.length === 1) {
                    label +=
                        ": " +
                        schedule.hours[0].timeInit +
                        " - " +
                        schedule.hours[0].timeEnd
                } else if (schedule.hours.length === 2) {
                    label +=
                        ": " +
                        schedule.hours[0].timeInit +
                        " - " +
                        schedule.hours[0].timeEnd +
                        " y " +
                        schedule.hours[1].timeInit +
                        " - " +
                        schedule.hours[1].timeEnd
                }

                labels.value.push(label)
            }
        )
    } else {
        labels.value = []
    }

    return labels.value
})

const hasAnyInformation = computed(() => {
    return (
        information.value?.enable_term_conditions ||
        information.value?.enable_warranty_policy ||
        information.value?.enable_shipment_policy ||
        information.value?.enable_return_policy ||
        information.value?.enable_frequent_asks
    )
})

/**
 *
 *  Life cicle events
 *
 *
 */

/**
 *
 *  Functions section
 *
 *
 */

function openNetwork(url: string) {
    window.open(url)
}
</script>
<template>
    <div>
        <div class="footer tw-py-8 max-container tw-max-w-full">
            <div
                class="tw-mx-auto tw-grid tw-grid-cols-12 tw-gap-5 lg:tw-justify-start"
            >
                <div class="tw-w-full tw-mb-8 tw-col-span-12 lg:tw-col-span-3">
                    <NuxtLink to="/">
                        <ApplicationLogo
                            class="tw-mx-auto lg:tw-m-0"
                            src="/img/logo-footer.svg"
                        />
                    </NuxtLink>
                </div>
                <div
                    class="footer-list tw-col-span-12 lg:tw-col-span-3 tw-flex tw-items-between tw-h-full"
                >
                    <label>{{ $t("home.about_us") }}</label>
                    <ul
                        class="tw-p-0 tw-list-none tw-flex tw-flex-col tw-items-center lg:tw-items-start lg:justify-between tw-h-full tw-gap-5 tw-text-customGray-300"
                    >
                        <li>
                            <q-icon name="fa-solid fa-info" color="white" />
                            <span
                                ><a
                                    href="https://dapamas.com"
                                    class="tw-text-secondary-400"
                                    >dapamas.com</a
                                >
                                una plataforma de
                                <a
                                    href="https://oceancard.com.mx/"
                                    class="tw-text-secondary-400"
                                    >Oceancard</a
                                ></span
                            >
                        </li>
                        <div
                            class="tw-p-0 tw-list-none tw-flex tw-flex-col tw-items-center lg:tw-items-start tw-gap-5 tw-text-customGray-300 tw-h-full"
                        >
                            <li v-if="contacts && contacts?.address">
                                <q-icon
                                    name="fa-solid fa-location-dot"
                                    color="white"
                                />
                                <span
                                    @click="
                                        openNetwork(
                                            'http://www.google.com/maps/place/' +
                                                contacts?.lat +
                                                ',' +
                                                contacts?.long
                                        )
                                    "
                                >
                                    {{ contacts?.address }}
                                </span>
                            </li>
                            <li v-if="contacts && contacts?.phones">
                                <q-icon
                                    name="fa-solid fa-phone"
                                    color="white"
                                />
                                {{
                                    Array.isArray(contacts?.phones)
                                        ? contacts?.phones.join(", ")
                                        : (contacts?.phones ?? "")
                                }}
                            </li>
                            <li v-if="contacts && contacts?.email">
                                <q-icon
                                    name="fa-solid fa-envelope"
                                    color="white"
                                />
                                {{ contacts?.email }}
                            </li>
                            <li v-if="scheduleLabels.length > 0">
                                <div
                                    class="tw-flex tw-flex-row tw-pr-1 tw-items-center"
                                >
                                    <q-icon
                                        name="fa-solid fa-clock"
                                        color="white"
                                        class="tw-text-center tw-mr-2 tw-mt-1 lg:tw-mt-0"
                                    />
                                    <div
                                        class="tw-flex tw-flex-col tw-w-max tw-break-words"
                                    >
                                        <span
                                            v-for="day in scheduleLabels"
                                            class="tw-break-all"
                                            >{{ day }}</span
                                        >
                                    </div>
                                </div>
                            </li>
                        </div>
                    </ul>
                </div>

                <div
                    class="footer-list tw-w-full tw-col-span-12 lg:tw-col-span-3 tw-h-full"
                >
                    <label class="!tw-text-white">Contacto comercial</label>
                    <ul
                        class="tw-p-0 tw-list-none tw-flex tw-flex-col tw-items-center lg:tw-items-start tw-gap-5 tw-text-customGray-300"
                    >
                        <li>
                            <q-icon name="fa-solid fa-info" color="white" />
                            <span
                                >Ingenius
                                <a
                                    href="https://ingeniuscuba.com"
                                    class="tw-text-secondary-400"
                                    >ingeniuscuba.com
                                </a></span
                            >
                        </li>
                        <div
                            class="tw-p-0 tw-list-none tw-flex tw-flex-col tw-items-center lg:tw-items-start tw-gap-5 tw-text-customGray-300 tw-h-full"
                        >
                            <li>
                                <q-icon
                                    name="fa-solid fa-location-dot"
                                    color="white"
                                />
                                <span>
                                    Calle 30 # 1910 entre 19 y 21. Municipio
                                    Playa
                                </span>
                            </li>
                            <li>
                                <q-icon
                                    name="fa-solid fa-phone"
                                    color="white"
                                />
                                +53 50995127
                            </li>
                            <li>
                                <q-icon
                                    name="fa-solid fa-envelope"
                                    color="white"
                                />
                                dapamas@ingeniuscuba.com
                            </li>
                        </div>
                    </ul>
                </div>

                <div
                    class="footer-list tw-w-full tw-h-full tw-col-span-12 lg:tw-col-span-3 tw-flex tw-items-between"
                    v-if="hasAnyInformation"
                >
                    <label>{{ $t("settings.information") }}</label>
                    <ul
                        class="tw-p-0 tw-h-full tw-list-none tw-flex tw-flex-col tw-items-center lg:tw-items-start tw-gap-5 tw-text-customGray-300"
                    >
                        <li v-if="information?.enable_about_us">
                            <NuxtLink to="/information/about">
                                <span class="tw-pr-1 tw-text-base">{{
                                    $t("settings.about_us")
                                }}</span>
                            </NuxtLink>
                        </li>
                        <li v-if="information?.enable_term_conditions">
                            <NuxtLink to="/information/terms-conditions">
                                <span class="tw-pr-1 tw-text-base">{{
                                    $t("settings.terms_conditions")
                                }}</span>
                            </NuxtLink>
                        </li>
                        <li v-if="information?.enable_warranty_policy">
                            <NuxtLink to="/information/warranty-policy">
                                <span class="tw-pr-1 tw-text-base">{{
                                    $t("settings.warranty_policy")
                                }}</span>
                            </NuxtLink>
                        </li>
                        <li v-if="information?.enable_shipment_policy">
                            <NuxtLink to="/information/shipment-policy">
                                <span class="tw-pr-1 tw-text-base">{{
                                    $t("settings.shipment_policy")
                                }}</span>
                            </NuxtLink>
                        </li>
                        <li v-if="information?.enable_return_policy">
                            <NuxtLink to="/information/return-policy">
                                <span class="tw-pr-1 tw-text-base">{{
                                    $t("settings.return_policy")
                                }}</span>
                            </NuxtLink>
                        </li>
                        <li v-if="information?.enable_frequent_asks">
                            <NuxtLink to="/information/frequent-asks">
                                <span class="tw-pr-1 tw-text-base">{{
                                    $t("settings.frequent_asks")
                                }}</span>
                            </NuxtLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="tw-w-full tw-h-[1px] tw-bg-primary-300"></div>

        <div class="footer tw-py-6 max-container">
            <div
                class="tw-flex tw-justify-between tw-flex-col-reverse tw-gap-7 sm:tw-flex-row tw-items-center tw-mx-auto"
            >
                <h6
                    class="tw-text-white tw-text-xs tw-font-medium tw-leading-none"
                >
                    {{ $t("landing.copyright") }}
                </h6>

                <ul
                    class="tw-list-none tw-p-0 tw-flex tw-flex-wrap tw-justify-center tw-gap-2"
                >
                    <li>
                        <img
                            src="../public/img/american-express.svg"
                            alt=""
                            class="images_log"
                        />
                    </li>
                    <li>
                        <img
                            src="../public/img/Visa-logos1.png"
                            alt=""
                            class="images_log"
                        />
                    </li>
                    <li>
                        <img
                            src="../public/img/masterCard.png"
                            alt=""
                            class="images_log"
                        />
                    </li>
                    <li>
                        <img
                            src="../public/img/discover.png"
                            alt=""
                            class="images_log"
                        />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<style scope>
.footer {
    @apply tw-bg-primary tw-text-white;
}

.footer-list {
    @apply tw-p-2 tw-text-center lg:tw-text-left tw-flex tw-flex-col tw-gap-4 tw-mx-auto lg:tw-mx-0;
}

.footer-list label {
    @apply tw-text-xl tw-font-bold;
}
.footer-list label:not(:first-child) {
    @apply tw-capitalize;
}
.footer-list li {
    @apply tw-text-base tw-cursor-pointer hover:tw-text-white tw-flex tw-gap-2 tw-items-center;
}

.footer-list li:hover a {
    @apply tw-text-secondary-200 tw-underline;
}

.images_log {
    @apply tw-w-10 tw-h-5;
}
</style>
