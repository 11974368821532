<template>
    <div
        class="tw-w-max tw-flex tw-flex-col tw-justify-center tw-gap-2 font-right-navbar tw-cursor-pointer"
        @click="goToCart"
    >
        <div class="tw-self-center tw-relative">
            <q-icon
                name="shopping_cart"
                size="30px"
                :class="`${shopCart.count > 0 && 'shake'} tw-text-customGray-300 hover:tw-text-primary hover:tw-transition-all hover:tw-duration-700`"
            />
            <q-badge
                rounded
                class="tw-text-white tw-bg-secondary tw-absolute -tw-top-2 -tw-right-2"
                floating
                v-show="shopCart.count > 0"
            >
                {{ shopCart.count }}
            </q-badge>
        </div>
        <span
            class="tw-text-customGray-400 tw-text-base tw-self-center"
            v-if="shopCart.count > 0"
        >
            {{ shopCart.subtotal_formatted }}
        </span>
        <span class="tw-text-customGray-400 tw-text-base tw-self-center" v-else>
            $ 00.00
        </span>
    </div>
</template>
<script lang="ts" setup>
import { useShopCartStore } from "~/stores/shopCartStore"

/**
 *
 *  Props and emits section
 *
 *
 */

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const router = useRouter()
const shopCartStore = useShopCartStore()
const { shopCart } = storeToRefs(shopCartStore)

/**
 *
 *  Life cicle events
 *
 *
 */

/**
 *
 *  Functions section
 *
 *
 */

async function goToCart() {
    router.push({ path: "/cart" })
}
</script>
