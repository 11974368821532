<script lang="ts" setup>
import type { User } from "~/models/User"

const props = defineProps<{
    light?: boolean
}>()

const user = useSanctumUser<User>()
const currentStoreStore = useCurrentStoreStore()
</script>
<template>
    <Select
        v-if="user?.stores_manager_of?.length > 0"
        :model-value="currentStoreStore.store"
        :options="
            user?.stores_manager_of.map((s) => {
                return { id: s.id, name: s.name, slug: s.slug }
            })
        "
        option-label="name"
        option-value="id"
        :label="$t('fields.store')"
        dense
        hide-bottom-space
        :color="!light && 'white'"
        :label-color="!light && 'white'"
        :item-color="!light && 'black'"
        map-options
        :emit-value="false"
        square
        dark
        borderless
        class="tw-min-w-32 store-select"
        :class="{ dark: !light }"
        @update:model-value="(s) => currentStoreStore.setCurrentStore(s)"
    >
    </Select>
</template>
<style></style>
