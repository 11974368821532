<template>
    <q-btn v-for="menu_item in menu" :label="$t(menu_item.title)" flat>
        <q-menu v-if="hasChildren(menu_item)">
            <q-list>
                <AdminTopMenuItem
                    v-for="children in menu_item.children"
                    :item="children"
                ></AdminTopMenuItem>
            </q-list>
        </q-menu>
    </q-btn>
</template>
<script lang="ts" setup>
import type { MenuItem } from "~/models/MenuItem"
import AdminTopMenuItem from "./AdminTopMenuItem.vue"

const props = defineProps<{
    menu: MenuItem[]
}>()

function hasChildren(item: MenuItem): boolean {
    if (item.children && item.children?.length > 0) return true
    else return false
}
</script>
