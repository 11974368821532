import type { Category } from "~/models/Category"

export const useSearchStore = defineStore("searchStore", {
    state: () => ({
        query: "",
        category: undefined as number | undefined | Category,
    }),
    getters: {
        getQuery(state) {
            return state.query
        },
        getCategory(state) {
            return state.category
        },
    },
    actions: {
        setQuery(newQuery: any) {
            this.query = newQuery
        },
        setCategory(newCategory: any) {
            this.category = newCategory
        },
    },
})
